import CompleteAccountRoutes from "@components/Router/CompleteAccountRoutes";
import useApplicationRoutes from "@hooks/useApplicationRoutes";
import { Spinner } from "@components/Spinner";
import useRenderCompleteAccount from "@hooks/useRenderCompleteAccount";

const CompleteAccountLayout = () => {
  const { applicationRoutes } = useApplicationRoutes();
  const { render } = useRenderCompleteAccount();

  if (!render) return <Spinner />;

  return (
    <div className="complete-account">
      <CompleteAccountRoutes applicationRoutes={applicationRoutes} />
    </div>
  );
};

export default CompleteAccountLayout;
