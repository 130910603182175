import LocalizedLink from "@components/I18n/LocalizedLink";
import { LanguageSwitcher } from "@components/I18n";
import React, { useEffect, useRef } from "react";
import useTenant from "@hooks/useTenant";

const CompactHeader = ({ setHeight }) => {
  const { logo, logoLoading } = useTenant();
  const ref = useRef(null);

  useEffect(() => {
    setHeight(ref.current.offsetHeight);

    const handleResize = () => {
      setHeight(ref.current.offsetHeight);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <div className={"logo-wrapper"} ref={ref}>
      <div className="img-wrapper" style={{ textAlign: "center" }}>
        {!logoLoading ? (
          <LocalizedLink path="/" className="logo">
            <img src={logo} alt="logo" height={"100px"} />
          </LocalizedLink>
        ) : null}
      </div>
      <div className={"d-flex align-items-center"}>
        <LanguageSwitcher />
      </div>
    </div>
  );
};

export default CompactHeader;
