import { func } from "prop-types";
import { Breadcrumbs } from "@components/Breadcrumbs";
import { Navigation } from "@components/Navigation";

const Header = ({ toggleSidebar, applicationRoutes }) => (
  <header className="nav-sidebar">
    <Navigation toggleSidebar={toggleSidebar} />
    <Breadcrumbs applicationRoutes={applicationRoutes} />
  </header>
);

Header.propTypes = {
  toggleSidebar: func.isRequired,
};

export default Header;
