import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {apiRoutes} from "@utils";
import {apiAction} from "@utils/http";

const reducerName = "mandate";

export const createMandateCreationUrlAction = createAsyncThunk(
    `${reducerName}/create-mandate-creation-url`,
    async (params, thunkAPI) => {
        try {
            const {data} = await apiAction({
                ...apiRoutes.mandate.creationUrl,
                data: {
                    presaleId: params.presaleId,
                    iban: params.iban
                }
            });
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue({error});
        }
    }
);

export const getMandatesAction = createAsyncThunk(
    `${reducerName}/get-mandates`,
    async (presaleId, thunkAPI) => {
        try {
            const {data} = await apiAction({
                ...apiRoutes.mandate.getMandates,
            });
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue({error});
        }
    }
);

export const processMandateActivationAction = createAsyncThunk(
    `${reducerName}/process-mandate-activation`,
    async (data, thunkAPI) => {
        console.log(data.mandateReference);
        console.log("----");
        try {
            await apiAction({
                ...apiRoutes.mandate.activation,
                urlParams: {
                    mandate_reference: data.mandateReference,
                },
                data: {
                    presale_id: data.presaleId,
                },
            });
        } catch (error) {
            console.log(error);
            return thunkAPI.rejectWithValue({error});
        }
    }
);

export const mandateSlice = createSlice({
    name: "mandate",
    initialState: {
        mandateActivation: false,
        mandateCreationUrl: "",
        data: [],
        isLoading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(createMandateCreationUrlAction.pending, (state) => {
            state.mandateCreationUrl = "";
        });
        builder.addCase(createMandateCreationUrlAction.rejected, (state) => {
            state.mandateCreationUrl = "";
        });
        builder.addCase(
            createMandateCreationUrlAction.fulfilled,
            (state, {payload}) => {
                state.mandateCreationUrl = payload.link;
            }
        );
        builder.addCase(processMandateActivationAction.pending, (state) => {
            state.mandateActivation = false;
        });
        builder.addCase(processMandateActivationAction.rejected, (state) => {
            //state.mandateActivation = false;
        });
        builder.addCase(
            processMandateActivationAction.fulfilled,
            (state, {payload}) => {
                state.mandateActivation = true;
            }
        );
        builder.addCase(getMandatesAction.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(getMandatesAction.rejected, (state, {payload}) => {
            state.data = [];
            state.isLoading = false;
            state.error = payload;
        });
        builder.addCase(getMandatesAction.fulfilled, (state, {payload}) => {
            state.isLoading = false;
            state.data = payload;
        });
    },
});

export const actions = mandateSlice.actions;

export default mandateSlice.reducer;
