import useIdentificationItemType from "@hooks/useIdentificationItemType";
import useNotification from "@hooks/useNotification";
import {
  addLicensePlateAction,
  getLicensePlatesAction,
  IDENTIFICATION_ITEM_TYPE_ANPR,
  addCleaningCardCreationRequestAction,
} from "@store/reducers/identification";
import {
  addMyCustomerInvoiceData,
  completeAccount,
  myAccount,
  editMyAccount,
  sendActivationMailAction,
} from "@store/reducers/profile";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";

const useAccountSettings = () => {
  const dispatch = useDispatch();
  const notify = useNotification();
  const { data: identificationItemType } = useIdentificationItemType();

  const {
    register,
    unregister,
    handleSubmit,
    errors,
    reset,
    getValues,
    watch,
    trigger,

    formState: { isValid },
  } = useForm({
    mode: "all",
    defaultValues: {
      vatNumber: "",
    },
  }); // initialise the hook
  const history = useHistory();
  const { account, isLoading } = useSelector((state) => state.profile.account);
  const licensePlates = useSelector(
    (state) => state.identification.licensePlates.data
  );
  const [render, setRender] = useState(false);

  const { invoiceData } = useSelector((state) => state.profile.invoiceData);
  const { accountStatus } = useSelector(
    (state) => state.profile.account.account
  );

  const { id: customerId } = useSelector(
    (state) => state.profile.account.account
  );

  const fetchLicensePlates = async () => {
    try {
      await dispatch(getLicensePlatesAction());
    } catch (e) {
      console.error(e);
    }
  };

  const sendActivationMail = async () => {
    const response = await dispatch(sendActivationMailAction());

    if (response.error) {
      notify.onlyIfContainsError(response);
    } else {
      notify.success("api.success.sendActivationMail");
    }
  };

  const updateSuccessfulRegistration = async () => {
    const response = await dispatch(
      completeAccount({
        successfulRegistration: true,
      })
    );

    if (response.error) {
      notify.onlyIfContainsError(response);
    } else {
      await myAccount();
      history.push("/");
    }
  };

  const sendIdentificationItemCreationRequest = async (data) => {
    if (data.requestIdentificationItem === true) {
      const accountData = {
        firstName: account.firstName,
        lastName: account.lastName,
        email: account.email,
        street: data.address.street,
        houseNumber: data.address.houseNumber,
        busNumber: data.address.busNumber,
        postalCode: data.address.postalCode,
        city: data.address.city,
        country: data.address.country,
      };
      const responseAddingAddress = await dispatch(editMyAccount(accountData));
      if (responseAddingAddress.error) {
        notify.onlyIfContainsError(responseAddingAddress);
        return false;
      } else {
        const cleaningCardCreationRequestData = {
          customerId: account.id,
          identificationItemTypeId: data.requestableIIT.id,
          numberOfRequestedIdentificationItems: data.numberOfRequestedItems,
        };
        const createIICreationRequestResponse = await dispatch(
          addCleaningCardCreationRequestAction(cleaningCardCreationRequestData)
        );
        if (createIICreationRequestResponse.error) {
          notify.onlyIfContainsError(responseAddingAddress);
          return false;
        }
      }
    }
    return true;
  };

  const updateSuccessfulRegistrationForCustomers = async (data) => {
    await updateSuccessfulRegistration();

    if (data.iiCode !== null && data.iiCode !== undefined) {
      data.iiCode = data.iiCode.toUpperCase();

      const body = {
        customerId,
        identificationItemTypeCode: "ANPR",
        name: data.iiCode,
      };
      const response = await dispatch(addLicensePlateAction(body));

      if (response.error) {
        notify.onlyIfContainsError(response);
        return false;
      }
    }

    if ((await sendIdentificationItemCreationRequest(data)) === true) {
      notify.success("api.success.modified");
    }
  };

  const updateSuccessfulRegistrationForBusinessCustomers = async (data) => {
    let invoiceDataResponse;

    if (
      (await sendIdentificationItemCreationRequest(data)) &&
      invoiceData === null
    ) {
      invoiceDataResponse = await dispatch(
        addMyCustomerInvoiceData({
          ...data.invoiceData,
          vatNumber: data.invoiceData.vatNumber.replace(/[\. ]+/g, ""),
        })
      );

      if (invoiceDataResponse.error) {
        notify.onlyIfContainsError(invoiceDataResponse);
      } else {
        notify.success("api.success.modified");
      }
    }

    if (
      (data.iiCode !== null &&
        data.iiCode !== undefined &&
        identificationItemType.some(
          (iit) => iit.code === IDENTIFICATION_ITEM_TYPE_ANPR
        )) ||
      !invoiceDataResponse?.error
    ) {
      data.iiCode = data.iiCode.toUpperCase();

      const body = {
        customerId,
        identificationItemTypeCode: "ANPR",
        name: data.iiCode,
      };

      const response = await dispatch(addLicensePlateAction(body));

      if (response.error) {
        notify.onlyIfContainsError(response);
      }
    }

    await updateSuccessfulRegistration();
  };

  const handleParticulierForm = async (data) => {
    await updateSuccessfulRegistrationForCustomers(data);
  };

  const handleZakelijkForm = async (data) => {
    await updateSuccessfulRegistrationForBusinessCustomers(data);
  };

  useEffect(async () => {
    if (accountStatus === "COMPLETED") {
      await fetchLicensePlates();
      setRender(true);
    }
  }, [accountStatus]);

  return {
    addForm: {
      register,
      unregister,
      handleSubmit,
      errors,
      reset,
      getValues,
      watch,
      trigger,
      isValid,
    },
    isLoading,
    account,
    handleParticulierForm,
    handleZakelijkForm,
    updateSuccessfulRegistration,
    sendActivationMail,
    licensePlates,
  };
};

export default useAccountSettings;
