import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiRoutes, http as apiAction } from "@utils";

const reducerName = "prepaid-sale-item";

export const myPrepaidSaleItems = createAsyncThunk(
  `${reducerName}`,
  async (_, thunkAPI) => {
    try {
      const { data } = await apiAction({
        ...apiRoutes.prepaidSaleItems.myPrepaidSaleItems,
      });
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

export const getMyPrepaidSaleItemById = createAsyncThunk(
  `${reducerName}-by-id`,
  async (params, thunkAPI) => {
    try {
      const { data } = await apiAction({
        ...apiRoutes.prepaidSaleItems.getMyPrepaidSaleItemById,
        urlParams: params,
      });
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

const prepaidSaleItemsSlice = createSlice({
  name: reducerName,
  initialState: {
    isLoading: false,
    error: null,
    list: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(myPrepaidSaleItems.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(myPrepaidSaleItems.rejected, (state, { payload }) => {
      state.list = [];
      state.isLoading = false;
      state.error = payload;
    });
    builder.addCase(myPrepaidSaleItems.fulfilled, (state, { payload }) => {
      state.isLoading = true;
      state.list = payload;
    });
  },
});

export const actions = prepaidSaleItemsSlice.actions;

export default prepaidSaleItemsSlice.reducer;
