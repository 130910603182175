import Header from "@components/Header/Header";
import DashboardRoutes from "@components/Router/DashboardRoutes";
import { Sidebar } from "@components/Sidebar";
import { Spinner } from "@components/Spinner";
import useApplicationRoutes from "@hooks/useApplicationRoutes";
import useRenderDashboard from "@hooks/useRenderDashboard";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useRenderCompleteAccount from "@hooks/useRenderCompleteAccount";

const Dashboard = () => {
  const { render, setToggleSidebar, sidebarHidden } = useRenderDashboard();
  const { applicationRoutes, sidebarRender } = useApplicationRoutes();
  const { accountStatus } = useSelector(
    (state) => state.profile.account.account
  );
  const { push } = useHistory();
  const { render: completeAccountRender } = useRenderCompleteAccount();

  useEffect(() => {
    (async () => {
      if (accountStatus === "EMAIL_CONFIRMED" && completeAccountRender) {
        push(
          `/${process.env.REACT_APP_DEFAULT_LOCALE}/complete-personal-details`
        );
      }
    })();
  }, [accountStatus, completeAccountRender]);

  if (!render || !sidebarRender) return <Spinner />;

  return (
    <>
      <div className="page-wrapper">
        <Sidebar
          status={sidebarHidden}
          applicationRoutes={applicationRoutes}
          toggleSidebar={setToggleSidebar}
        />
        <div className="content">
          <Header
            applicationRoutes={applicationRoutes}
            toggleSidebar={setToggleSidebar}
          />
          <main>
            <DashboardRoutes applicationRoutes={applicationRoutes} />
          </main>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
