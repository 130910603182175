import AuthRoutes from "@components/Router/AuthRoutes";
import { Row } from "reactstrap";

const AuthLayout = () => {
  return (
    <div className="auth">
      <AuthRoutes />
    </div>
  );
};

export default AuthLayout;
