import IntlNumberEUR from "@components/ReactIntl/IntlNumberEUR";
import useCredit from "@hooks/useCredit";
import useNavigation from "@hooks/useNavigation";
import { FormattedMessage } from "react-intl";
import MediaQuery from "react-responsive";
import { NavLink, withRouter } from "react-router-dom";
import { Nav, Navbar, NavbarText, NavItem } from "reactstrap";
import LocalizedNavLink from "@components/I18n/LocalizedNavLink";
import { LanguageSwitcher } from "@components/I18n";

const Navigation = (props) => {
  const { account, balance, logout } = useNavigation(props);
  const { saleItems } = useCredit();

  return (
    <Navbar expand="md">
      <MediaQuery query="(max-device-width: 764px)">
        <span
          className="nav-toggler d-block d-md-none"
          onClick={props.toggleSidebar}
        >
          <i className="ti-close ti-menu"></i>
        </span>
      </MediaQuery>
      <div className="nav-items-wrapper">
        <MediaQuery query="(min-device-width: 764px)">
          <NavbarText className="welcome font-18">
            <FormattedMessage
              id="main.sidebar.welcome"
              values={{
                firstName: account.firstName,
                lastName: account.lastName,
              }}
            />
          </NavbarText>
        </MediaQuery>
        <MediaQuery query="(max-device-width: 764px)">
          <NavbarText className="font-18">
            <FormattedMessage
              id="main.sidebar.welcomeMobile"
              values={{
                firstName: account.firstName,
                lastName: account.lastName,
              }}
            />
          </NavbarText>
        </MediaQuery>
        {saleItems.length ? (
          <>
            <span className="double-pipe font-18">||</span>
            <Nav navbar>
              <NavItem className="mr-1">
                <LocalizedNavLink
                  path="/dashboard/credit"
                  className="font-18 border-bottom-enlarge"
                >
                  <MediaQuery query="(min-device-width: 764px)">
                    <FormattedMessage id="main.navigation.credit" />
                  </MediaQuery>
                  <MediaQuery query="(max-device-width: 764px)">
                    <FormattedMessage id="main.navigation.creditMobile" />
                  </MediaQuery>
                </LocalizedNavLink>
              </NavItem>
              &nbsp;&nbsp;
              <NavItem>
                <LocalizedNavLink
                  path="/dashboard/credit"
                  className={"font-18"}
                >
                  <NavbarText
                    className="badge badge-light font-18"
                    color="white"
                  >
                    <IntlNumberEUR value={balance} />
                  </NavbarText>
                </LocalizedNavLink>
              </NavItem>
            </Nav>
          </>
        ) : null}
      </div>
      <div>
        <LanguageSwitcher />
      </div>
    </Navbar>
  );
};
export default withRouter(Navigation);
