import PreviewInvoice from "@components/Invoice/PreviewInvoice";
import PrivateRoute from "@components/Router/PrivateRoute";
import PreviewPrepaidSaleItem from "@components/Webshop/PreviewPrepaidSaleItem";
import useApp from "@hooks/useApp";
import AuthLayout from "@layouts/Auth";
import CompleteAccount from "@layouts/CompleteAccount";
import DashboardLayout from "@layouts/Dashboard";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { BrowserRouter, Route } from "react-router-dom";
import PreviewDiscountVoucher from "@components/DiscountVoucher/PreviewDiscountVoucher";
import { appStrings, LocalizedRouter } from "@components/I18n";
import { Toastr } from "@components/Toastr";
import { LocalizedSwitch } from "@components/I18n/LocalizedSwitch";

const App = () => {
  const { bootApp, projectStyling } = useApp();

  if (!bootApp) return null;

  return (
    <LocalizedRouter RouterComponent={BrowserRouter} appStrings={appStrings}>
      <HelmetProvider>
        <Helmet>
          <style>{`${projectStyling}`}</style>
        </Helmet>

        <Toastr />
      </HelmetProvider>

      <LocalizedSwitch>
        <Route path="/invoice-download/:uuid" component={PreviewInvoice} />
        <Route
          path="/voucher-download/:uuid"
          component={PreviewDiscountVoucher}
        />
        <Route
          path="/webshop-download/:uuid"
          component={PreviewPrepaidSaleItem}
        />
        <Route path="/auth" component={AuthLayout} />
        <Route path="/activate" component={AuthLayout} />
        <Route path="/password" component={AuthLayout} />
        <Route path="/username" component={AuthLayout} />
        <Route
          path={"/discount-vouchers/:discountVoucherUuid/fulfill"}
          component={AuthLayout}
        />
        <PrivateRoute
          path="/complete-personal-details"
          component={CompleteAccount}
        />
        <PrivateRoute path="/" component={DashboardLayout} />
      </LocalizedSwitch>
    </LocalizedRouter>
  );
};

export default App;
