import { useEffect, useState } from "react";
import { getSubscriptionAction } from "@store/reducers/subscriptions";
import { useDispatch, useSelector } from "react-redux";

import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { calculateSaleItemPrice } from "@store/reducers/saleItemCategories";
import { setSaleItemData } from "@store/reducers/sales";
import useCheckout from "@hooks/useCheckout";

const useSubscriptions = () => {
  const [saleItemIdParam, setSaleItemIdParam] = useState(0);
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.subscriptions);
  const { saleItemPrice } = useSelector((state) => state.saleItem);
  const { account, invoiceData } = useSelector((state) => state.profile);
  const location = useLocation();
  const { register, handleSubmit, errors } = useForm();
  const [render, setRender] = useState(false);
  const checkout = useCheckout();
  const history = useHistory();

  const calculatePrice = async (saleItemId) => {
    await dispatch(calculateSaleItemPrice({ saleItemId: saleItemId }));
  };

  const handleSubmitForm = () => {
    checkout.handleCheckout();
  };

  useEffect(async () => {
    if (location.state && location.state.subscriptionId) {
      let subscriptionId = location.state.subscriptionId;
      const resp = await dispatch(
        getSubscriptionAction({ subscriptionId: subscriptionId })
      );
      setSaleItemIdParam(resp.payload.saleItemId);
      await calculatePrice(resp.payload.saleItemId);
      setRender(true);
    } else {
      history.push("/dashboard/subscriptions");
    }
  }, []);

  useEffect(() => {
    if (saleItemIdParam && saleItemPrice.calculatedPrice) {
      dispatch(
        setSaleItemData({
          saleItemConfigurable: false,
          identificationItemId: Number(data.identificationItemId),
          id: saleItemIdParam,
          price: saleItemPrice.calculatedPrice,
        })
      );
    }
  }, [saleItemIdParam, saleItemPrice]);

  return {
    data,
    render,
    saleItemPrice,
    account: account.account,
    invoiceData: invoiceData.invoiceData,
    invoiceDataError: invoiceData.error,
    addForm: {
      register,
      handleSubmit,
      errors,
      handleSubmitForm,
    },
  };
};
export default useSubscriptions;
